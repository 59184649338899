import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import logo from '../../images/l-shadow-dota@2x.png'
import * as styles from './styles.module.scss'
import BackgroundImg from '../shared/BackgroundImg'
import info from '../../images/info-2.svg'

class PricingTiles extends Component {
  constructor(props) {
    super(props)

    this.monthlyScheme = {
      amateur: {
        price: {
          eur: 5,
          cent: 95,
        },
        paymentLink:
          'https://subscriptions.zoho.com/subscribe/6e3fe2b0c6a675f154310c9acd5a24926a2bba0f6b053217ad482bf477cf719e/Shadow-Dota-Amateur-Monthly',
        paymentLinkVAT:
          'https://subscriptions.zoho.com/subscribe/6e3fe2b0c6a675f154310c9acd5a24926a2bba0f6b053217ad482bf477cf719e/Shadow-Dota-Amateur-Monthly-Ust',
      },
      // semipro: {
      //   price: {
      //     eur: 99,
      //     cent: 95,
      //   },
      //   paymentLink:
      //     'https://subscriptions.zoho.com/subscribe/6e3fe2b0c6a675f154310c9acd5a24926a2bba0f6b053217ad482bf477cf719e/Shadow-Dota-Semi-Pro-Monthly',
      //   paymentLinkVAT:
      //     'https://subscriptions.zoho.com/subscribe/6e3fe2b0c6a675f154310c9acd5a24926a2bba0f6b053217ad482bf477cf719e/Shadow-Dota-Semi-Pro-Monthly-Ust',
      // },
      pro: {
        price: {
          eur: 1299,
          cent: 95,
        },
        paymentLink: '',
      },
    }

    this.biannualScheme = {
      amateur: {
        total: '28,74',
        price: {
          eur: 4,
          cent: 79,
        },
        paymentLink: `https://subscriptions.zoho.com/subscribe/6e3fe2b0c6a675f154310c9acd5a24926a2bba0f6b053217ad482bf477cf719e/Shadow-Dota-Amateur-Biannual`,
        paymentLinkVAT: `https://subscriptions.zoho.com/subscribe/6e3fe2b0c6a675f154310c9acd5a24926a2bba0f6b053217ad482bf477cf719e/Shadow-Dota-Amateur-Biannual-Ust`,
      },
      // semipro: {
      //   price: {
      //     eur: 79,
      //     cent: 95,
      //   },
      //   total: '479,70',
      //   paymentLink: `https://subscriptions.zoho.com/subscribe/6e3fe2b0c6a675f154310c9acd5a24926a2bba0f6b053217ad482bf477cf719e/Shadow-Dota-Semi-Pro-Biannual`,
      //   paymentLinkVAT: `https://subscriptions.zoho.com/subscribe/6e3fe2b0c6a675f154310c9acd5a24926a2bba0f6b053217ad482bf477cf719e/Shadow-Dota-Semi-Pro-Biannual-Ust`,
      // },
      pro: {
        price: {
          eur: 999,
          cent: 95,
        },
        total: '5999,70',
        paymentLink: '',
      },
    }

    this.state = {
      checked: false,
      scheme: this.monthlyScheme,
      isLoading: true,
      isEnterprise: false,
      region: 'DE',
    }

    this.toggleSlider = () => {
      let scheme = this.biannualScheme
      if (this.state.checked) scheme = this.monthlyScheme

      this.setState({
        checked: !this.state.checked,
        scheme: scheme,
      })
    }

    this.toggleEnterprise = () => {
      this.setState({
        isEnterprise: !this.state.isEnterprise,
      })
    }
  }

  componentDidMount() {
    let self = this
    const setData = function(data, key) {
      self.setState({
        isLoading: false,
        region: eu_countries.includes(data[key])
          ? 'EU'
          : data[key] === de_country
          ? 'DE'
          : 'SomewhereElse',
      })
    }

    const eu_countries = [
      'AT',
      'BE',
      'BG',
      'HR',
      'CY',
      'CZ',
      'DK',
      'EE',
      'FI',
      'FR',
      'GR',
      'HU',
      'IE',
      'IT',
      'LV',
      'LT',
      'LU',
      'MT',
      'NL',
      'PL',
      'PT',
      'RO',
      'SK',
      'SI',
      'ES',
      'SE',
      'GB',
    ]
    const de_country = 'DE'

    // failsafe in case geoip-db stops working for some reason
    fetch(`https://geoip-db.com/json/`)
      .then(res => res.json())
      .then(data => setData(data, 'country_code'))
      .catch(() => {
        this.setState({ isLoading: false })
      })
  }

  subscribeNow(plan) {
    let href = ''

    if (this.state.isEnterprise) {
      if (this.state.region === 'DE')
        href = this.state.scheme[plan].paymentLinkVAT
      else href = this.state.scheme[plan].paymentLink
    } else {
      href = this.state.scheme[plan].paymentLinkVAT
    }

    return (
      <a className='button' href={href}>
        Subscribe Now
      </a>
    )
  }

  render() {
    if (this.state.isLoading) return <div />
    else {
      return (
        <section className={styles.heroWrapper}>
          <div className={styles.wrapContainer}>
            <div className={styles.planIntro}>
              <h4>PLANS & PRICINGS</h4>
              <div className={styles.imageContainer}>
                <img className={styles.logoMobile} src={logo} alt='Shadow' />
              </div>
              <div className={styles.enterprise}>
                <span className={styles.month}>Private</span>
                <label className={styles.switchi}>
                  <input
                    id='switch'
                    type='checkbox'
                    checked={this.state.isEnterprise}
                    onChange={this.toggleEnterprise}
                  />
                  <span className={styles.slider} />
                </label>
                <span className={styles.month}>Enterprise</span>
              </div>
              <div className={styles.planMonth}>
                <span className={styles.month}>MONTHLY</span>
                <label className={styles.switchi}>
                  <input
                    id='switch'
                    type='checkbox'
                    checked={this.state.checked}
                    onChange={this.toggleSlider}
                  />
                  <span className={styles.slider} />
                </label>
                <span className={styles.month}>BI-ANNUAL</span>
                <span className={styles.offer}>SAVE 20%</span>
              </div>
            </div>
            <div className={styles.cardsContainer}>
              <div className={styles.card}>
                <div className={styles.container}>
                  <div className={styles.topContainer}>
                    <div className={styles.tierName}>AMATEUR</div>
                    <div className={styles.tierPriceContainer}>
                      <span className={styles.tierPrice}>
                        {this.state.scheme.amateur.price.eur},
                        <span className={styles.cent}>
                          {this.state.scheme.amateur.price.cent}
                        </span>
                      </span>
                      <span className={styles.tierPriceUnit}>
                        <span className={styles.unitEur}>EUR</span>
                        <span className={styles.unitMo}>/ Mo</span>
                      </span>
                    </div>
                    <div className={styles.schemeInfo}>
                      {this.state.checked ? (
                        <span>
                          <b>{this.state.scheme.amateur.total} &euro;</b> paid
                          once every 6 Months{' '}
                        </span>
                      ) : (
                        ''
                      )}
                    </div>
                    <hr />
                  </div>
                  <div className={styles.bottomContainer}>
                    <div className={styles.featureList}>
                      <div className={styles.featureContainer}>
                        <span>Match Details</span>
                        <img
                          alt='match'
                          className={styles.infoIcon}
                          src={info}
                        />
                        <span className={styles.tooltiptext}>
                          Get access to a detailed breakdown of every match,
                          including your scrims. See everything that happened at
                          a glance on an easy to view timeline.
                        </span>
                      </div>
                      <div className={styles.featureContainer}>
                        <span>Draft Tool</span>
                        <img
                          alt='draft'
                          className={styles.infoIcon}
                          src={info}
                        />
                        <span className={styles.tooltiptext}>
                          Quickly practice drafting and get a live summary of
                          meta picks and bans for each drafting stage and team.
                        </span>
                      </div>
                      <div className={styles.featureContainer}>
                        <span>Maps (Limited Data)</span>
                        <img
                          alt='maps'
                          className={styles.infoIcon}
                          src={info}
                        />
                        <span className={styles.tooltiptext}>
                          <div>
                            <strong>
                              Access limited to games played in the last 30
                              days. No access to recent pro matches (3 days).
                            </strong>
                          </div>
                          <br />
                          <div>
                            <b>Ward Map</b>
                          </div>
                          <div>
                            Visualises typical ward locations of many matches at
                            once. Learn how to counter ward specific opponents.
                          </div>
                          <div>
                            <b>Smoke Map</b>
                          </div>
                          <div>
                            Shows live replays of individual smokes, or collates
                            a group of them on the map. Find trends in your
                            opponents smoke usages.
                          </div>
                          <div>
                            <b>Movement Map</b>
                          </div>
                          <div>
                            View movements of players and teams. Compare farming
                            patterns and know how each player approaches ganks.
                          </div>
                        </span>
                      </div>
                      <div className={styles.featureContainer}>
                        <span>Single User</span>
                        <img
                          alt='draft'
                          className={styles.infoIcon}
                          src={info}
                        />
                        <span className={styles.tooltiptext}>
                          Only you can access the tool.
                        </span>
                      </div>
                    </div>
                    <div className={styles.buttonContainer}>
                      {this.subscribeNow('amateur')}
                    </div>
                  </div>
                </div>
              </div>
              {/*<div className={styles.centreCard}>*/}
              {/*<div className={styles.container}>*/}
              {/*<div className={styles.topContainer}>*/}
              {/*<div className={styles.tierName}>SEMI-PRO</div>*/}
              {/*<div className={styles.tierPriceContainer}>*/}
              {/*<span className={styles.tierPrice}>*/}
              {/*{this.state.scheme.semipro.price.eur},*/}
              {/*<span className={styles.cent}>*/}
              {/*{this.state.scheme.semipro.price.cent}*/}
              {/*</span>*/}
              {/*</span>*/}
              {/*<span className={styles.tierPriceUnit}>*/}
              {/*<span className={styles.unitEur}>EUR</span>*/}
              {/*<span className={styles.unitMo}>/ Mo</span>*/}
              {/*</span>*/}
              {/*</div>*/}
              {/*<div className={styles.schemeInfo}>*/}
              {/*{this.state.checked ? (*/}
              {/*<span>*/}
              {/*<b>{this.state.scheme.semipro.total} &euro;</b> paid*/}
              {/*once every 6 Months{' '}*/}
              {/*</span>*/}
              {/*) : (*/}
              {/*''*/}
              {/*)}*/}
              {/*</div>*/}
              {/*<hr />*/}
              {/*</div>*/}
              {/*<div className={styles.bottomContainer}>*/}
              {/*<div className={styles.featureList}>*/}
              {/*<div className={styles.featureContainer}>*/}
              {/*<span>Match Details</span>*/}
              {/*<img*/}
              {/*alt='match'*/}
              {/*className={styles.infoIcon}*/}
              {/*src={info}*/}
              {/*/>*/}
              {/*<span className={styles.tooltiptext}>*/}
              {/*Get access to a detailed breakdown of every match,*/}
              {/*including your scrims. See everything that happened at*/}
              {/*a glance on an easy to view timeline.*/}
              {/*</span>*/}
              {/*</div>*/}
              {/*<div className={styles.featureContainer}>*/}
              {/*<span>Draft Tool</span>*/}
              {/*<img*/}
              {/*alt='draft'*/}
              {/*className={styles.infoIcon}*/}
              {/*src={info}*/}
              {/*/>*/}
              {/*<span className={styles.tooltiptext}>*/}
              {/*Quickly practice drafting and get a live summary of*/}
              {/*meta picks and bans for each drafting stage and team.*/}
              {/*</span>*/}
              {/*</div>*/}
              {/*<div className={styles.featureContainer}>*/}
              {/*<span>Maps (Unlimited)</span>*/}
              {/*<img*/}
              {/*alt='maps'*/}
              {/*className={styles.infoIcon}*/}
              {/*src={info}*/}
              {/*/>*/}
              {/*<span className={styles.tooltiptext}>*/}
              {/*<div>*/}
              {/*<b>Ward Map</b>*/}
              {/*</div>*/}
              {/*<div>*/}
              {/*Visualises typical ward locations of many matches at*/}
              {/*once. Learn how to counter ward specific opponents.*/}
              {/*</div>*/}
              {/*<div>*/}
              {/*<b>Smoke Map</b>*/}
              {/*</div>*/}
              {/*<div>*/}
              {/*Shows live replays of individual smokes, or collates*/}
              {/*a group of them on the map. Find trends in your*/}
              {/*opponents smoke usages.*/}
              {/*</div>*/}
              {/*<div>*/}
              {/*<b>Movement Map</b>*/}
              {/*</div>*/}
              {/*<div>*/}
              {/*View movements of players and teams. Compare farming*/}
              {/*patterns and know how each player approaches ganks.*/}
              {/*</div>*/}
              {/*</span>*/}
              {/*</div>*/}
              {/*<div className={styles.featureContainer}>*/}
              {/*<span>Ranked Scout</span>*/}
              {/*<img*/}
              {/*alt='scout'*/}
              {/*className={styles.infoIcon}*/}
              {/*src={info}*/}
              {/*/>*/}
              {/*<span className={styles.tooltiptext}>*/}
              {/*Track players and their smurf accounts to learn what*/}
              {/*they practice in their solo games and be on top of the*/}
              {/*meta.*/}
              {/*</span>*/}
              {/*</div>*/}
              {/*<div className={styles.featureContainer}>*/}
              {/*<span>Online Support</span>*/}
              {/*<img*/}
              {/*alt='support'*/}
              {/*className={styles.infoIcon}*/}
              {/*src={info}*/}
              {/*/>*/}
              {/*<span className={styles.tooltiptext}>*/}
              {/*Reach out to our staff directly and at any time! We*/}
              {/*provide online support Mo-Fr 8am to 7 pm (GMT+2).*/}
              {/*</span>*/}
              {/*</div>*/}
              {/*</div>*/}
              {/*<div className={styles.buttonContainer}>*/}
              {/*{this.subscribeNow('semipro')}*/}
              {/*</div>*/}
              {/*</div>*/}
              {/*</div>*/}
              {/*</div>*/}
              <div className={styles.card}>
                <div className={styles.container}>
                  <div className={styles.topContainer}>
                    <div className={styles.tierName}>PRO</div>
                    <div className={styles.tierPriceContainer}>
                      <span className={styles.onRequest}>Get In Touch</span>
                    </div>
                    <hr />
                  </div>
                  <div className={styles.bottomContainer}>
                    <div className={styles.featureList}>
                      <div className={styles.featureContainer}>
                        <span>Match Details</span>
                        <img
                          alt='match'
                          className={styles.infoIcon}
                          src={info}
                        />
                        <span className={styles.tooltiptext}>
                          Get access to a detailed breakdown of every match,
                          including your scrims. See everything that happened at
                          a glance on an easy to view timeline.
                        </span>
                      </div>
                      <div className={styles.featureContainer}>
                        <span>Draft Tool</span>
                        <img
                          alt='draft'
                          className={styles.infoIcon}
                          src={info}
                        />
                        <span className={styles.tooltiptext}>
                          Quickly practice drafting and get a live summary of
                          meta picks and bans for each drafting stage and team.
                        </span>
                      </div>
                      <div className={styles.featureContainer}>
                        <span>Maps (Unlimited)</span>
                        <img
                          alt='maps'
                          className={styles.infoIcon}
                          src={info}
                        />
                        <span className={styles.tooltiptext}>
                          <div>
                            <b>Ward Map</b>
                          </div>
                          <div>
                            Visualises typical ward locations of many matches at
                            once. Learn how to counter ward specific opponents.
                          </div>
                          <div>
                            <b>Smoke Map</b>
                          </div>
                          <div>
                            Shows live replays of individual smokes, or collates
                            a group of them on the map. Find trends in your
                            opponents smoke usages.
                          </div>
                          <div>
                            <b>Movement Map</b>
                          </div>
                          <div>
                            View movements of players and teams. Compare farming
                            patterns and know how each player approaches ganks.
                          </div>
                        </span>
                      </div>
                      <div className={styles.featureContainer}>
                        <span>Ranked Scout</span>
                        <img
                          alt='scout'
                          className={styles.infoIcon}
                          src={info}
                        />
                        <span className={styles.tooltiptext}>
                          Track players and their smurf accounts to learn what
                          they practice in their solo games and be on top of the
                          meta.
                        </span>
                      </div>
                      <div className={styles.featureContainer}>
                        <span>Dedicated Account Manager</span>
                        <img
                          alt='dedicated'
                          className={styles.infoIcon}
                          src={info}
                        />
                        <span className={styles.tooltiptext}>
                          Reach out to our staff directly and at any time! We
                          provide online support Mo-Fr 8am to 7 pm (GMT+2).
                        </span>
                      </div>
                      <div className={styles.featureContainer}>
                        <span>Hero Analytics</span>
                        <img
                          alt='analytics'
                          className={styles.infoIcon}
                          src={info}
                        />
                        <span className={styles.tooltiptext}>
                          Shape the meta: Profit from our exclusive data based
                          on only the highest quality ranked games and be the
                          first to find the counter to meta heroes.
                        </span>
                      </div>
                      <div className={styles.featureContainer}>
                        <span>Scrims</span>
                        <img
                          alt='scrims'
                          className={styles.infoIcon}
                          src={info}
                        />
                        <span className={styles.tooltiptext}>
                          Securely upload your Scrims to your private match
                          library. Get a detailed overview and instant analytics
                          to help you learn from your practice games.
                        </span>
                      </div>
                      <div className={styles.featureContainer}>
                        <span>Up to 10 Users</span>
                        <img
                          alt='upto5'
                          className={styles.infoIcon}
                          src={info}
                        />
                        <span className={styles.tooltiptext}>
                          Maximum of 10 users can access the tool.
                        </span>
                      </div>
                    </div>
                    <div className={styles.demoButtonContainer}>
                      <a
                        className='button'
                        onClick={() => {
                          window.LeadBooster.trigger('open')
                        }}
                      >
                        Request a Demo
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.planFooter}>
              <div>
                Unlock your{' '}
                <span className={styles.footerMsg}>competitive advantage</span>{' '}
                now!
              </div>
            </div>
          </div>
          <StaticQuery
            query={graphql`
              {
                placeholderImage: file(
                  relativePath: { eq: "bg-dota-cta.jpg" }
                ) {
                  childImageSharp {
                    gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                  }
                }
              }
            `}
            render={data => (
              <BackgroundImg
                className={styles.backgroundImg}
                image={data.placeholderImage.childImageSharp.gatsbyImageData}
              />
            )}
          />
        </section>
      )
    }
  }
}

export default PricingTiles
